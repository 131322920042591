import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from './Nav.module.css';
// import { Divide as Hamburger } from 'hamburger-react';
import './hamburger.css';

function NavbarHamburger() {
  const [navbarToggle, setNavbarToggle] = useState(false);

  const handleToggle = () => {
    setNavbarToggle((prev) => !prev);
  };

  return (
    <Navbar expand='lg' className={styles.hide_hamburger}>
      <Container>
        {/* Left Navbar */}
        <div className={styles.logo}>
          <h1>
            <a href='#home' className={styles.hamburger_h1}>
              DM
            </a>
          </h1>
        </div>
        <div className={styles.hamburger_cont}>
          <div className={`btn ${!navbarToggle ? 'not-active' : 'active'}`} onClick={handleToggle}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <ul className={`menuNav ${navbarToggle ? ' showMenu' : ''}`} onClick={handleToggle}>
          <div className='margin-box'></div>
          <Nav.Link
            href='#home'
            className={`${styles.text_navbar} ${styles.text_navbar_hamburger}`}>
            HOME
          </Nav.Link>
          {/* <Nav.Link
            href='#services'
            className={`${styles.text_navbar} ${styles.text_navbar_hamburger}`}
          >
            SERVICES
          </Nav.Link> */}
          <Nav.Link
            href='#projects'
            className={`${styles.text_navbar} ${styles.text_navbar_hamburger}`}>
            PROJECTS
          </Nav.Link>
          <Nav.Link
            href='#skills'
            className={`${styles.text_navbar} ${styles.text_navbar_hamburger}`}>
            SKILLS
          </Nav.Link>
          <Nav.Link
            href='#about'
            className={`${styles.text_navbar} ${styles.text_navbar_hamburger}`}>
            ABOUT
          </Nav.Link>
          <Nav.Link
            href='#contact'
            className={`${styles.text_navbar} ${styles.text_navbar_hamburger}`}>
            CONTACT
          </Nav.Link>
        </ul>

        {/* <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          className={styles.span_navbar}
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className={`${styles.align_navbar} me-auto d-block`}>
            <Nav.Link href='#home' className={styles.text_navbar}>
              HOME
            </Nav.Link>
            <Nav.Link href='#services' className={styles.text_navbar}>
              SERVICES
            </Nav.Link>
            <Nav.Link href='#projects' className={styles.text_navbar}>
              PROJECTS
            </Nav.Link>
            <Nav.Link href='#skills' className={styles.text_navbar}>
              SKILLS
            </Nav.Link>
            <Nav.Link href='#about' className={styles.text_navbar}>
              ABOUT
            </Nav.Link>
            <Nav.Link href='#contact' className={styles.text_navbar}>
              CONTACT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default NavbarHamburger;
