//Images for icons
import html from './img/html.png';
import css from './img/css.png';
import mysql from './img/mysql.png';
import react from './img/react.png';
import js from './img/js.png';
import typescript from './img/typescript.png';
import node from './img/node.png';
import csharp from './img/csharp.png';
import express from './img/express.png';
import mongo from './img/mongo.png';
import aws from './img/aws.png';
import php from './img/php.png';
import spring from './img/spring.png';
import wp from './img/wp.png';
import python from './img/python.png';
import django from './img/django.png';
import docker from './img/docker.png';
import tailwind from './img/tailwind.png';
import bootstrap from './img/bootstrap.png';
import java from './img/java.png';
import springboot from './img/springboot.png';
import next from './img/next.js.png';

const skill = [
  { id: 1, title: 'HTML', icon: html },
  { id: 2, title: 'CSS', icon: css },
  { id: 3, title: 'JS', icon: js },
  { id: 4, title: 'TypeScript', icon: typescript },
  { id: 5, title: 'React', icon: react },
  { id: 6, title: 'Next.js', icon: next },
  { id: 7, title: 'MySQL', icon: mysql },
  { id: 8, title: 'MongoDB', icon: mongo },
  { id: 9, title: 'AWS', icon: aws },
  { id: 10, title: 'Node.js', icon: node },
  { id: 11, title: 'Express', icon: express },
  { id: 12, title: 'Python', icon: python },
  { id: 13, title: 'Django', icon: django },
  { id: 14, title: 'Java', icon: java },
  { id: 15, title: 'Spring', icon: spring },
  { id: 16, title: 'Spring Boot', icon: springboot },
  // { id: 17, title: 'C#', icon: csharp },
  { id: 18, title: 'PHP', icon: php },
  { id: 19, title: 'WordPress', icon: wp },
  { id: 20, title: 'Docker', icon: docker },
  { id: 21, title: 'Tailwind', icon: tailwind },
  { id: 22, title: 'Bootstrap', icon: bootstrap },
];

export default skill;
